"use strict";

// @future Consider using audioSprites
// @future Simplify how this and loadAssets are configured.
module.exports = function getSounds(phaserScene) {

    const sounds = {
        shark: phaserScene.sound.add('shark', { loop: false }),
        shrimp: phaserScene.sound.add('shrimp', { loop: false }),
        horse: phaserScene.sound.add('horse', { loop: false }),
        good: phaserScene.sound.add('good', { loop: false }),
        bad: phaserScene.sound.add('bad', { loop: false }),
        life: phaserScene.sound.add('life', { loop: false }),
        escape: phaserScene.sound.add('escape', { loop: false }),
        theme: phaserScene.sound.add('theme', { loop: true, volume: 0.2 }),
    };

    return sounds;
};
