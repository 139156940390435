"use strict";

module.exports = function(featureName, options) {
	this.name = featureName;
	this.disabledMessage = options.disabledMessage;
	this.dependencyState = true;
	this.uiSelectors = "uiSelectors" in options && typeof options.uiSelectors !== "undefined" ? options.uiSelectors : [];
	this.dependencies = "dependencies" in options && typeof options.dependencies !== "undefined" ? options.dependencies : [];
	this.state = "state" in options && typeof options.state !== "undefined" ? options.state : true;
	this.enableCb = "enableCb" in options && typeof options.enableCb !== "undefined" ? options.enableCb : function(){};
	this.disableCb = "disableCb" in options && typeof options.disableCb !== "undefined" ? options.disableCb : function(){};
};