"use strict";

// @future Refactor this.

var AnalyticsEvent = require("./AnalyticsEvent");

module.exports = function (options) {
	var parent = AnalyticsEvent;
	
	parent.call(this, "Error", options.action, options);
	
	// @future Set type with a constant i.e. AnalyticsEvent.ERRORTYPE.
	this.type = "error";
};
module.exports.prototype = Object.create(AnalyticsEvent.prototype);
module.exports.prototype.constructor = module.exports;