"use strict";

// @todo Move this back into the main class.
module.exports = function createCrab(phaserScene, xPosition, yPosition, bounce, gravity) {
    const crab = phaserScene.physics.add.sprite(xPosition, yPosition * 0.5, 'crab');
    crab.setBounce(bounce);
    crab.setCollideWorldBounds(true);
    crab.setGravity(0, gravity);
    crab.setDataEnabled();
    crab.data.set('invulnerable', false);

    return crab;
};