"use strict";

/* globals Phaser */

// @todo Review remaining Phaser examples.
// @todo Review: https://phaser.io/examples/v3/category/display/align
// @todo Review: https://phaser.io/examples/v3/view/game-objects/container/
// @todo Impliment merged input: https://github.com/GaryStanton/phaser3-merged-input
// @todo Research Phaser Tweens
// @todo Handle Scene management such as destroy, restart, etc.
// @todo Does the registry need to be used for scores, etc? https://github.com/photonstorm/phaser3-examples/blob/master/public/src/scenes/registry%20data%20exchange.js
// @todo Use for Highscore Board: https://github.com/photonstorm/phaser3-examples/blob/master/public/src/input/keyboard/enter%20name.js
// @todo Impliment Transitions between Scenes: https://phaser.io/examples/v3/view/scenes/transition-test-1
// @todo Review https://phaser.io/examples/v3/view/scenes/scene-files-payload
// @todo Do Scenes need to impliment the shutdown method? Do they need to clear timers and input listeners?

// @future Review https://phaser.discourse.group/t/how-many-pixels-wondering-about-target-resolution/4456/14

// @future Consider switching to an event driven architecture using Phaser 3 Event Emitters.
// @future Consider turning scenes into Phaser.Scene child-classes.

// @future Consider for handling differences between Mobile, Desktop, and Arcade display: https://phaser.io/examples/v3/view/scalemanager/mobile-game-example

module.exports = function GameLogic(options = {}) {

	//Private Scope
	let _this = {};

    //Public Methods
    this.init = function init(){
        let phaserConfig = _this.phaserConfig;

        // @todo Game size is not being set correctly.
        _this.game = new Phaser.Game(phaserConfig);
    };

    //Private Methods

    //Public Properties

    //Private Properties
    // @todo Double check what the aspect ratio should be and impliment it.
    // _this.aspectRatio = 0.5625;

    _this.phaserConfig = undefined;
    _this.game = undefined;

    //Constructor
	(function(_this, options){
        // Reveal or hide private scope dependent on options.
        if (options.revealPrivateScope) {
            this._this = _this;
        }

        // @todo This may need to be adapted based on https://photonstorm.github.io/phaser3-docs/Phaser.Scale.ScaleManager.html
        // @todo Review: https://phaser.discourse.group/t/scaling-game-to-fit-entire-window/6219/3
        // setBaseDimensions();

        const loadScenes = require("./loadScenes");
        const scenes = loadScenes();

        const phaserConfig = JSON.parse(JSON.stringify(options.gameConfig));
        phaserConfig.scene = scenes;

        _this.phaserConfig = phaserConfig;

    }).bind(this)(_this, options);
};