"use strict";

module.exports = function loadAssets(phaserScene) {
    phaserScene.load.image('background', 'img/background.png');
    phaserScene.load.image('foreground', 'img/foreground.png');
    phaserScene.load.image('life', 'img/life.png');
    phaserScene.load.spritesheet('shrimp', 'img/shrimp.png', { frameWidth: 96, frameHeight: 96 });
    phaserScene.load.spritesheet('jelly', 'img/jelly.png', { frameWidth: 96, frameHeight: 96 });
    phaserScene.load.spritesheet('ugly', 'img/ugly.png', { frameWidth: 96, frameHeight: 96 });
    phaserScene.load.spritesheet('lion', 'img/lion.png', { frameWidth: 96, frameHeight: 96 });
    phaserScene.load.spritesheet('horse', 'img/horse.png', { frameWidth: 96, frameHeight: 96 });
    phaserScene.load.spritesheet('fishOne', 'img/fish-one.png', { frameWidth: 96, frameHeight: 96 });
    phaserScene.load.spritesheet('fishTwo', 'img/fish-two.png', { frameWidth: 96, frameHeight: 96 });
    phaserScene.load.spritesheet('fishThree', 'img/fish-three.png', { frameWidth: 96, frameHeight: 96 });
    phaserScene.load.spritesheet('crab', 'img/crab.png', { frameWidth: 108, frameHeight: 108 });
    phaserScene.load.spritesheet('shark', 'img/shark.png', { frameWidth: 215, frameHeight: 85 });
    phaserScene.load.audio('shark', ['audio/chomp1.wav']);
    phaserScene.load.audio('shrimp', ['audio/point_normal.wav']);
    phaserScene.load.audio('horse', ['audio/point_special.wav']);
    phaserScene.load.audio('good', ['audio/cursor_select.wav']);
    phaserScene.load.audio('bad', ['audio/bloop.wav']);
    phaserScene.load.audio('life', ['audio/succes-3.ogg']);
    phaserScene.load.audio('escape', ['audio/succes-2.ogg']);
    phaserScene.load.audio('theme', ['audio/jazz_flute.mp3']);
};
