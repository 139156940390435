"use strict";

module.exports = [
    {
        touchValue: 0,
        escapes: false,
        hostile: true,
        animationLeft: "jellyLeft",
        animationRight: "jellyRight",
        velocityModifier: 0.25,
        type: "fish",
        touchSound: "bad",
    },
    {
        touchValue: 0,
        escapes: false,
        hostile: true,
        animationLeft: "uglyLeft",
        animationRight: "uglyRight",
        velocityModifier: 0.5,
        type: "fish",
        touchSound: "bad",
    },
    {
        touchValue: 0,
        escapes: false,
        hostile: true,
        animationLeft: "lionLeft",
        animationRight: "lionRight",
        velocityModifier: 0.75,
        type: "fish",
        touchSound: "bad",
    },
    {
        touchValue: 10,
        escapes: false,
        hostile: false,
        animationLeft: "fishOneLeft",
        animationRight: "fishOneRight",
        velocityModifier: 0.75,
        type: "fish",
        touchSound: "good",
    },
    {
        touchValue: 10,
        escapes: false,
        hostile: false,
        animationLeft: "fishTwoLeft",
        animationRight: "fishTwoRight",
        velocityModifier: 0.5,
        type: "fish",
        touchSound: "good",
    },
    {
        touchValue: 10,
        escapes: false,
        hostile: false,
        animationLeft: "fishThreeLeft",
        animationRight: "fishThreeRight",
        velocityModifier: 1,
        type: "fish",
        touchSound: "good",
    },
    {
        touchValue: 50,
        escapes: false,
        hostile: false,
        animationLeft: "horseLeft",
        animationRight: "horseRight",
        velocityModifier: 1.5,
        type: "fish",
        touchSound: "horse",
    },
];