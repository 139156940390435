"use strict";

module.exports = {
	//General Settings
	// Some of these need to be updated with every release
	currentAppVersionCode: 1,
	
	//Debugging:
	enableAnalytics: false,
	analyticsPrefix: "",
	enableLocalLog: false, //Determines if errors are logged locally in _this.errorLog;
	revealPrivateScope: false,
};
