"use strict";

// @todo Update this.
// @todo Re-impliment highscores.
// @future Re-add non-arcade features such as AFUI, full Logger support, EULA notices, ScreenCenter, etc.

module.exports = function App(options) {
	// @future Remove jQuery where it isn't nessecary.
	// @future Replace actions that can be made WCAG AA compliant.
	
	/* globals $ */

	//Private Scope
	const _this = {};

	//Private Classes

	//Public Methods
	this.init = function () { //Automatic Initialization method.
		// @future Should parts of this be moved to main.js?
		// @future Should part of this be moved to a config method?
		
		window.document.addEventListener("DOMContentLoaded", _this.DOMContentLoadedCb , false);
	};

	//Private Methods

	_this.DOMContentLoadedCb = (function(window, _this, $){return function() {

		// @future Determine if this should be fatal.
		_this.featureManager.registerFeature("database", {
			disabledMessage: "The ability to save high scores.",
			uiSelectors: [],
		});

		_this.gameLogic.init();

		_this.featureManager.setOptions({
			onDisableCb: function(){},
		});

	};})(window, _this, $);

	_this.updateDatabase = function () {
		const highScores = _this.gameLogic.getHighScores();
		const highScoresJson = JSON.stringify(highScores);
		
		_this.database.updateHighScores(highScoresJson);
	}.bind(this);

	//Private Properties
	// @future these be listed as a properties, methods, or somethign else?
	_this.gameLogic = options.gameLogic;
	_this.featureManager = options.featureManager;
	_this.database = options.database;

	//General settings
	//Variables used for debugging:
	_this.revealPrivateScope = options.appSettings.revealPrivateScope;

	//Constructor
	(function(_this, options){

        // Reveal or hide private scope dependent on options.
		// @future Port this to all modules.
        if (options.revealPrivateScope) {
            this._this = _this;
        }

    }).bind(this)(_this, options);
};
