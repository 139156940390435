"use strict";

module.exports = function($, options) {
	// @future If more than one message is generated due to dependencies, show them all at once.
	// @future Add enabledMessage and onEnabledCb
	// @future Make this independent of jQuery.
	// @future Ability to modify selectors and callbacks
	
	//Private Scope
	var _this = {};
	
	_this.FeatureItem = require("./FeatureItem");
	_this.features = [];
	_this.featuresByName = {};
	_this.$ = $;
	_this.defaultOptions = {
		enabledClass: "featureEnabled",
		disabledClass: "featureDisabled",
		onEnableCb: function(){},
		onDisableCb: function(){},
	};
	_this.enabledClass = undefined;
	_this.disabledClass = undefined;
	_this.onEnableCb = undefined;
	_this.onDisableCb = undefined;
	
	this.registerFeature = function(featureName, options) {
		var featureItem = new _this.FeatureItem(featureName, options);
		_this.features.push(featureItem);
		_this.featuresByName[featureName] = featureItem;
		_this.checkDependencies();
	};
	
	this.getFeatureState = function(featureName) {
		if(!(featureName in _this.featuresByName)) {
			return undefined;
		}
		
		var featureItem = _this.featuresByName[featureName];
		var state = featureItem.state && featureItem.dependencyState;
		return state;
	};
	
	this.getDisabledFeatureMessages = function() {
		var features = _this.features;
		var disabledFeatureMessages = [];
		var featureItem;
		var l = features.length;
		for(var i = 0; i < l; i++) {
			featureItem = features[i];
			if(!featureItem.state) {
				disabledFeatureMessages.push(featureItem.disabledMessage);
			}
		}
		
		return disabledFeatureMessages;
	};
	
	this.enableFeature = function(featureName) {
		if(!(featureName in _this.featuresByName)) {
			return undefined;
		}
		
		var featureItem = _this.featuresByName[featureName];
		var featureUiSelectors = featureItem.uiSelectors;
		var element;

		if(featureItem.state) {
			return featureItem.dependencyState;
		}
		
		featureItem.state = true;
		_this.checkDependencies();
		if(featureItem.dependencyState) {
			_this.setUiStates(featureItem, true);
		}
		
		return featureItem.dependencyState;
	};
	
	this.disableFeature = function(featureName) {
		if(!(featureName in _this.featuresByName)) {
			return undefined;
		}
		var featureItem = _this.featuresByName[featureName];
		var element;

		if(!featureItem.state) {
			return false;
		}
		
		featureItem.state = false;
		_this.checkDependencies();
		_this.setUiStates(featureItem, false);
		
		return featureItem.disabledMessage;
	};
	
	this.setOptions = function(options){
		var defaultOptions = {
			enabledClass: _this.enableClass,
			disabledClass: _this.disableClass,
			onEnableCb: _this.onEnableCb,
			onDisableCb: _this.onDisableCb,
		};
		
		options = typeof options !== "undefined" ? options : {};
		options = Object.assign(defaultOptions, options);
		
		_this.enabledClass = options.enabledClass;
		_this.disabledClass = options.disabledClass;
		_this.onEnableCb = options.onEnableCb;
		_this.onDisableCb = options.onDisableCb;
	};

	_this.setUiStates = function(featureItem, state) {
		// @future For accessibility, role="button" aria-disabled="true" to links.
		// @future For accessibility, disabled="disabled" to buttons and form elements.
		// @future Ensure form elements that were disabled before being disabled by this method aren't reenabled by this method.
		var uiSelectors = featureItem.uiSelectors;
		var l = uiSelectors.length;
		var oldClass;
		var newClass;
		var element;
		var cb;
		var onCb;
		var onCbArguments;
		
		if (state) {
			oldClass = _this.disabledClass;
			newClass = _this.enabledClass;
			cb = featureItem.enableCb;
			onCb = _this.onEnableCb;
			// @future This should be enabledMessage
			onCbArguments = featureItem.name;
		} else {
			oldClass = _this.enabledClass;
			newClass = _this.disabledClass;
			cb = featureItem.disableCb;
			onCb = _this.onDisableCb;
			onCbArguments = featureItem.disabledMessage;
		}

		for(var i = 0; i < l; i++){
			element = $(uiSelectors[i]);
			element.removeClass(oldClass);
			element.addClass(newClass);
		}
		cb();
		onCb(onCbArguments);
	}.bind(this);
	
	_this.checkFeatureDependencies = function(dependencies) {
		var dependencyKey;
		var featuresByName = _this.featuresByName;
		var l = dependencies.length;
		
		for(var i = 0; i < l; i++) {
			dependencyKey = dependencies[i];
			if (typeof featuresByName[dependencyKey] !== "undefined" && featuresByName[dependencyKey].state === false) {
				return false;
			}
		}
		
		return true;
	}.bind(this);
	
	//Note that this will not consider states that are set be other feature dependencies.
	//Each feature should specify both it's dependencies and the dependencies of it's dependencies.
	// @future Use recurssion or dependency inheritance.
	_this.checkDependencies = function() {
		var featuresByName = _this.featuresByName;
		var features = _this.features;
		var dependencyState;
		var l = features.length;
		var featureItem;
		for(var i = 0; i < l; i++) {
			featureItem = features[i];
			dependencyState = _this.checkFeatureDependencies(featureItem.dependencies);
			if(featureItem.dependencyState !== dependencyState){
				featureItem.dependencyState = dependencyState;
				if(featureItem.state === true) {
					if(dependencyState) {
						_this.setUiStates(featureItem, true);
					} else {
						_this.setUiStates(featureItem, false);
					}
				}
			}
		}
	}.bind(this);
	
	//Constructor
	options = typeof options !== "undefined" ? options : {};
	options = Object.assign(_this.defaultOptions, options);
	
	_this.enabledClass = options.enabledClass;
	_this.disabledClass = options.disabledClass;
	_this.onEnableCb = options.onEnableCb;
	_this.onDisableCb = options.onDisableCb;
};