"use strict";

// @todo Add additional scenes.

module.exports = function loadScenes(options = {}) {
    const Stage = require("./scenes/stage/Stage");
    const stage = new Stage(options);
    const scenes = [stage];

    return scenes;
};