"use strict";

module.exports = function(category, action, options) {
	this.type = "event";
	this.executionTime = options.executionTime;
	this.category = options.category;
	this.action = options.action; // Event Identifier i.e. "functionName Invalid Input"
	// @future Should it be ensured that value is a real number?
	this.value = "value" in options && typeof options.value !== "undefined" ? options.value : undefined;
	this.actionPrefix = "actionPrefix" in options && typeof options.value !== "undefined" ? options.actionPrefix : "";
	this.sanitaryString = "sanitaryString" in options ? options.sanitaryString : undefined;
	this.unsanitaryString = "unsanitaryString" in options ? options.unsanitaryString : undefined;
	this.sanitaryData = "sanitaryData" in options ? options.sanitaryData : undefined;
	this.unsanitaryData = "unsanitaryData" in options ? options.unsanitaryData : undefined;
	
	this.getAction = function() {
		var action = this.actionPrefix + this.action;
		return action;
	};
	
	this.getLabel = function() {
		var label = "ExecutionTime:" + this.executionTime;
		if(typeof this.sanitaryString !== undefined) {
			label += ', SanitaryString: ' + this.sanitaryString;
		}
		return label;
	};
	
};