"use strict";

module.exports = function loadAnimations(phaserScene) {
        // @todo Tweak Crab animations. Enlarge right claw and match with other sprites.
        phaserScene.anims.create({
            key: 'walk',
            frames: phaserScene.anims.generateFrameNumbers('crab', { start: 0, end: 3 }),
            frameRate: 10,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'stand',
            frames: [ { key: 'crab', frame: 0 } ],
            frameRate: 10
        });

        phaserScene.anims.create({
            key: 'shrimpLeft',
            frames: phaserScene.anims.generateFrameNumbers('shrimp', { start: 0, end: 1 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'shrimpRight',
            frames: phaserScene.anims.generateFrameNumbers('shrimp', { start: 2, end: 3 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'jellyLeft',
            frames: phaserScene.anims.generateFrameNumbers('jelly', { start: 0, end: 1 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'jellyRight',
            frames: phaserScene.anims.generateFrameNumbers('jelly', { start: 2, end: 3 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'uglyLeft',
            frames: phaserScene.anims.generateFrameNumbers('ugly', { start: 0, end: 1 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'uglyRight',
            frames: phaserScene.anims.generateFrameNumbers('ugly', { start: 2, end: 3 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'fishOneLeft',
            frames: phaserScene.anims.generateFrameNumbers('fishOne', { start: 0, end: 1 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'fishOneRight',
            frames: phaserScene.anims.generateFrameNumbers('fishOne', { start: 2, end: 3 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'fishTwoLeft',
            frames: phaserScene.anims.generateFrameNumbers('fishTwo', { start: 0, end: 1 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'fishTwoRight',
            frames: phaserScene.anims.generateFrameNumbers('fishTwo', { start: 2, end: 3 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'fishThreeLeft',
            frames: phaserScene.anims.generateFrameNumbers('fishThree', { start: 0, end: 1 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'fishThreeRight',
            frames: phaserScene.anims.generateFrameNumbers('fishThree', { start: 2, end: 3 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'sharkLeft',
            frames: phaserScene.anims.generateFrameNumbers('shark', { start: 0, end: 1 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'sharkRight',
            frames: phaserScene.anims.generateFrameNumbers('shark', { start: 2, end: 3 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'horseLeft',
            frames: phaserScene.anims.generateFrameNumbers('horse', { start: 0, end: 1 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'horseRight',
            frames: phaserScene.anims.generateFrameNumbers('horse', { start: 2, end: 3 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'lionLeft',
            frames: phaserScene.anims.generateFrameNumbers('lion', { start: 0, end: 1 }),
            frameRate: 5,
            repeat: -1
        });

        phaserScene.anims.create({
            key: 'lionRight',
            frames: phaserScene.anims.generateFrameNumbers('lion', { start: 2, end: 3 }),
            frameRate: 5,
            repeat: -1
        });
};