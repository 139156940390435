"use strict";

// @future Convert ES5 "classes" to ES6 Classes
// @future Standardize the use of terms such as settings, config, and options.

(function(window) {
	
	// @future Consider if anything needs to be moved into the App module or rolled up from other modules. 

	const App = require('App');
	const defaultAppSettings = require('defaultAppSettings');
	const appSettings = require('appSettings');
	const Logger = require('logger/Logger');
	const FeatureManager = require('feature-manager/FeatureManager');
	const Database = require('database/Database');
	const GameLogic = require('game/GameLogic');
	const gameConfig = require("game/gameConfig");
	
	// @Future: Every module should set it's own defaults, unless they are explicitly required in app.js
	const mergedAppSettings = Object.assign(defaultAppSettings, appSettings);
	
	const startTime = Date.now();
	
	// @future Configure these directly in the settings file, 
	const loggerOptions = {
			localLogState: appSettings.enableLocalLog,
			appVersionCode: appSettings.currentAppVersionCode,
			// @future These shouldnt have to be inverted.
			gaState: appSettings.enableAnalytics,
			analyticsPrefix: appSettings.analyticsPrefix,
			startTime: startTime,
	};
	
	const logger = new Logger(loggerOptions);
	const featureManager = new FeatureManager(window.$);
	const database = new Database(featureManager, logger);

	const gameLogicOptions = {
		gameConfig: gameConfig
	};

	// @future Should this be done within App?
	const gameLogic = new GameLogic(gameLogicOptions);
	
	const appOptions = {
			appSettings: mergedAppSettings,
			featureManager: featureManager,
			database: database,
			gameLogic: gameLogic,
	};
	
	const app = new App(appOptions);
	
	app.init();
	
	if(appSettings.revealPrivateScope === true) {
		window.app = app;
	}

})(window);
