"use strict";

module.exports = function(options) {
	// @future Refine management of sanitary and unsanitary data/strings
	// @future Extend this with additional analytics functionality.
	// @future Add ability to permenately disable/enable local logs, clears, and gets.
	// @future Consider making this a wrapper for a seperate class that only handles events and errors.
	// @future Don't require Facebook Analytics or Google Analytics, activate features on a granular basis.
	// @future Instead of hard coding events and errors, define them dynamically so additional types like warnings can be defined.
	
	//Private Scope
	var _this = {};
	
	//Private Properties
	_this.defaultOptions = {
			// @future Make this more finite so that events and errors can be disabled for Google, Facebook, and local seperately.
			localLogState: true,
			gaState: false,
			analyticsPrefix: "",
			startTime: Date.now(),
			facebookConnectPluginState: false,
	};
	
	// @future Determine where this should be located.
	options = Object.assign(_this.defaultOptions, options);
	
	_this.AnalyticsEvent = require("./AnalyticsEvent");
	_this.AnalyticsError = require("./AnalyticsError");
	_this.AnalyticsView = require("./AnalyticsView");
	_this.analyticsTimerId = undefined;
	_this.googleAnalyticsQueue = [];
	_this.lastAnalyticsHit = 0;
	_this.localErrorLog = []; //Local error storage.
	_this.localEventLog = []; //Local event storage.
	_this.startTime = options.startTime;
	_this.gaState = options.gaState;
	_this.localLogState = options.localLogState;
	_this.analyticsPrefix = options.analyticsPrefix;
	_this.facebookConnectPluginState = options.facebookConnectPluginState;
	_this.ANALYTICSDELAY = 1000;
	_this.defaultAnalyticsOptions = {
			actionPrefix: _this.analyticsPrefix,
	};
	_this.ga = undefined;
	_this.facebookConnectPlugin = undefined;
	_this.globalState = false;
	_this.globalErrorQueue = [];
	_this.globalEventQueue = [];
	_this.globalViewQueue = [];
	
	//Public Methods
	this.enable = function() {
		var globalErrorQueue = _this.globalErrorQueue;
		var globalEventQueue = _this.globalEventQueue;
		var globalViewQueue = _this.globalViewQueue;
		var globalErrorQueueLength = globalErrorQueue.length;
		var globalEventQueueLength = globalEventQueue.length;
		var globalViewQueueLength = globalViewQueue.length;
		var argumentsItem;
		var i;
		
		_this.globalState = true;
		
		for(i = 0; i < globalErrorQueueLength; i++){
			argumentsItem = globalErrorQueue.shift();
			this.logError(argumentsItem[0], argumentsItem[1]);
		}
		
		for(i = 0; i < globalEventQueueLength; i++){
			argumentsItem = globalEventQueue.shift();
			this.logEvent(argumentsItem[0], argumentsItem[1], argumentsItem[2]);
		}
		
		for(i = 0; i < globalViewQueueLength; i++){
			this.logView(globalViewQueue.shift());
		}
	};
	
	this.disable = function() {
		_this.globalState = false;
	};
	
	// @future Change how this is handled.
	this.startGoogleAnalytics= function(ga, trackingId, dispatchPeriod) {
		if(_this.globalState === false) {
			return false;
		}
		
		_this.ga = ga;
		
		ga.startTrackerWithId(trackingId, dispatchPeriod);
		
		_this.processGaItems();
		
		return true;
	};

	this.enableGoogleAnalytics = function() {
		_this.gaState = true;
		
		_this.processGaItems();
	};
	
	this.disableGoogleAnalytics = function() {
		_this.gaState = false;
		
		if (typeof _this.analyticsTimerId !== "undefined") {
			window.clearTimeout(_this.analyticsTimerId);
			_this.analyticsTimerId = undefined;
		}
		
		return true;
	};
	
	// @future How should this reflect ready state?
	this.getGoogleAnalyticsState = function() {
		return _this.gaState;
	};
	
	this.getGoogleAnalyticsQueueLength = function() {
		return _this.googleAnalyticsQueue.length;
	};

	// @future Change how this is handled.
	this.startFacebookAnalytics = function(facebookConnectPlugin) {
		if(_this.globalState === false){
			return false;
		}
		
		_this.facebookConnectPlugin = facebookConnectPlugin;
		
		return true;
	};
	
	this.purgeGoogleAnalyticsQueue = function() {
		_this.googleAnalyticsQueue = [];
	};

/*
	this.enableFacebookAnalytics = function() {
		_this.facebookConnectPluginState = true;
	};
	
	this.disableFacebookAnalytics = function() {
		_this.facebookConnectPluginState = false;
	};
	
	this.toggleFacebookAnalyticsState = function(facebookConnectPluginState) {
		if(typeof facebookConnectPluginState !== undefined) {
			_this.facebookConnectPluginState = facebookConnectPluginState;
		} else {
			_this.facebookConnectPluginState = !_this.facebookConnectPluginState;
		}
		
		return _this.facebookConnectPluginState;
	};
	
	// @future How should this reflect ready state?
	this.getFacebookAnalyticsState = function() {
		return _this.facebookConnectPluginState;
	};
*/
	this.enableLocalLog = function() {
		_this.localLogState = true;
		return true;
	};
	
	this.disableLocalLog = function() {
		// @future Determine if log should be cleared when disabled.
		_this.localLogState = false;
		return false;
	};
	
	this.getLocalLogState = function() {
		return _this.localLogState;
	};
	
	this.getLocalErrorLog = function() {
		return _this.localErrorLog.slice(0);
	};
	
	this.getLocalEventLog = function() {
		return _this.localEventLog.slice(0);
	};
	
	this.logError = function(action, options) {
		options = typeof options !== "undefined" ? options : {};
		options = Object.assign(_this.defaultAnalyticsOptions, options);
		options.action = action;
		options.executionTime = (Date.now() - _this.startTime) / 1000;
		
		if(_this.globalState) {
			_this.globalErrorQueue.push([action, options]);
		}

		// @future Add options to strip unanitary data.

		var analyticsError = new _this.AnalyticsError(options);
		
		_this.unshiftGaItem(analyticsError);
		
		if (_this.localLogState === true) {
			_this.localErrorLog.push(analyticsError);
		}
	};
	
	this.logEvent = function(category, action, options) {
		options = typeof options !== "undefined" ? options : {};
		options = Object.assign(_this.defaultAnalyticsOptions, options);
		options.category = category;
		options.action = action;
		options.executionTime = (Date.now() - _this.startTime) / 1000;
		
		if(_this.globalState) {
			_this.globalEventQueue.push([category, action, options]);
		}
		
		// @future Add options to strip unanitary data.
		
		var analyticsEvent = new _this.AnalyticsEvent(category, action, options);

		_this.unshiftGaItem(analyticsEvent);
		
		if (_this.localLogState === true) {
			_this.localEventLog.push(analyticsEvent);
		}
	};
	
	this.logView = function(view) {
		if(_this.globalState) {
			_this.globalViewQueue.push(view);
		}
		
		var options = {
				view : view,
		};
		
		var analyticsView = new _this.AnalyticsView(options);
		
		_this.unshiftGaItem(analyticsView);
	};
	
	_this.unshiftGaItem = function(analyticsItem) {
		if (_this.globalState !== false && _this.gaState !== false) {
			_this.googleAnalyticsQueue.unshift(analyticsItem);
	
			_this.processGaItems();
		}
	}.bind(this);
	
	//Do not move this into the scope of processGaItems
	//Moving this method outside of the processGaItems scope prevents recursion from generating a scope chain.
	_this.processGaItemsCb = function () {
		_this.analyticsTimerId = undefined;
		
		_this.processGaItems();
	}.bind(this);

	//Private Methods
	_this.processGaItems = function() {
		// @future assess this for memory consumption during long recurrsion/scope chains.
		
		if (_this.globalState === false || typeof _this.ga === "undefined" || _this.analyticsTimerId !== undefined || _this.gaState === false || _this.googleAnalyticsQueue.length === 0) { {}
			return;
		}
		
		var ANALYTICSDELAY = _this.ANALYTICSDELAY;
		var currentTime = Date.now();
		var lastAnalyticsHit = _this.lastAnalyticsHit;

		if (lastAnalyticsHit + ANALYTICSDELAY < currentTime || lastAnalyticsHit === 0) {
			var analyticsItem = _this.googleAnalyticsQueue.pop();
			var analyticsItemType = analyticsItem.type;
			if (analyticsItemType === "view") {
				_this.ga.trackView(analyticsItem.view);
				_this.lastAnalyticsHit = currentTime;
			} else if (analyticsItemType === "event" || analyticsItemType === "error") {
				_this.ga.trackEvent(analyticsItem.category, analyticsItem.getAction(), analyticsItem.getLabel(), analyticsItem.value);
				_this.lastAnalyticsHit = currentTime;
			} else {
				this.logError("Logger.processGaItems: Bad analyticsItemType", {unsanitaryData: analyticsItemType});
			}
		}
		
		if(_this.googleAnalyticsQueue.length > 0) {
			_this.analyticsTimerId = window.setTimeout(_this.processGaItemsCb, lastAnalyticsHit + ANALYTICSDELAY - currentTime);
		}
	}.bind(this);
	
};