"use strict";

module.exports = {
    touchValue: 50,
    escapes: true,
    hostile: false,
    animationLeft: "shrimpLeft",
    animationRight: "shrimpRight",
    velocityModifier: 0.75,
    type: "shrimp",
    touchSound: "shrimp",
};