"use strict";

/* globals Phaser */

// @Future for Fish and Shrimp Generation: https://github.com/photonstorm/phaser3-examples/blob/master/public/src/physics/arcade/rtree%20test.js
// @Future for Shark: https://phaser.io/examples/v3/view/physics/arcade/tween-velocity
// @Future for fish generation: https://phaser.io/examples/v3/view/game-objects/group/sprite-pool

const loadAssets = require("./loadAssets");
const loadAnimations = require("./loadAnimations");
const getSounds = require("./getSounds");
const createCrab = require("./createCrab");
const swimmerData = require("./swimmerData");
const shrimpData = require("./shrimpData");

module.exports = function Stage(options = {}) {

	//Private Scope
	let _this = {};

    //Local Scope.
    let local = this;

    // Public Methods

    // Phaser Scene Hooks
    this.init = function init () {	
    };

    this.preload = function preload () {
        _this.sceneScope = this;
        _this.loadAssets(this);
    };

    this.create = function create () {
        // @future Move this into a method.
        _this.extraLives = 0;
        _this.lives = 3;
        _this.score = 0;
        _this.crab = undefined;
        _this.shark = undefined;
        _this.shrimp = undefined;
        _this.swimmers = undefined;

        _this.initSceneValues();

        const sceneScope = _this.sceneScope;
        _this.loadAnimations(sceneScope);
        _this.sounds = _this.getSounds(sceneScope);

        _this.sounds.theme.play();

        const cameraWidth = _this.cameraWidth;
        const cameraHeight = _this.cameraHeight;
        this.add.image(cameraWidth * 0.5, cameraHeight * 0.5, 'background').setDisplaySize(cameraWidth, cameraHeight);
        this.add.image(cameraWidth * 0.5, cameraHeight * 0.5, 'foreground').setDisplaySize(cameraWidth, cameraHeight).setDepth(1);

        _this.initCrab();
        const crab = _this.crab;

        const cursors = this.input.keyboard.createCursorKeys();
        _this.cursors = cursors;

        const swimmers = this.physics.add.group();
        _this.swimmers = swimmers;

        const collectSwimmerCb = _this.collectSwimmerCb;
        this.physics.add.overlap(crab, swimmers, collectSwimmerCb, null, this);

        const spawnSwimmersCb = _this.spawnSwimmersCb;
        _this.spawnSwimmersTimer = this.time.addEvent({ delay: 1500, callback: spawnSwimmersCb, callbackScope: this, loop: true });

        const textPositionX = cameraWidth / 2;

        _this.scoreText = this.add.text(textPositionX, 32, '00000', { font: 'bold 56px silkscreen,Monaco,Courier,monospace', fill: '#000' });

        _this.livesDisplay = this.physics.add.group({
            key: 'life',
            repeat: _this.lives -1,
            setXY: { x: 54, y: 54, stepX: 64 }
        });
    };

    this.update = function update () {
        _this.parseControls();
        _this.updateShark();
        _this.updateShrimp();

        const swimmerUpdateCb = _this.swimmerUpdateCb;
        _this.swimmers.children.each(swimmerUpdateCb);
    };

    // Private Methods
    _this.loadAssets = loadAssets.bind(this);
    _this.loadAnimations = loadAnimations.bind(this);
    _this.getSounds = getSounds.bind(this);
    _this.createCrab = createCrab.bind(this);

    _this.gainLivesFromScore = function gainLivesFromScore(){
        const score = _this.score;
        const extraLives = _this.extraLives;
        const scoreQuotient = Math.floor(score / 500);

        if (extraLives < scoreQuotient) {
            _this.extraLives = extraLives + 1;
            _this.sounds.life.play();
            _this.updateLives(1);
        }
    }.bind(this);

    _this.updateShark = function updateShark(){
        const score = _this.score;
        const cameraHeight = _this.cameraHeight;
        const crab = _this.crab;
        let shark = _this.shark;

        if(typeof shark === "undefined" && score >= 500){
            shark = _this.sceneScope.physics.add.sprite(0, cameraHeight * 0.5, 'shark');
            shark.setVelocityX(0);
            shark.anims.play('sharkRight', true);
            shark.setDataEnabled();
            shark.data.set('hostile', true);
            shark.data.set('type', "shark");
            shark.data.set('touchValue', 0);
            shark.data.set('touchSound', 'shark');

            const sharkWidth = shark.frame.width;
            const sharkHeight = shark.frame.height;
            shark.setSize(sharkWidth, sharkHeight, false);

            const collectSwimmerCb = _this.collectSwimmerCb;
            _this.sceneScope.physics.add.overlap(crab, shark, collectSwimmerCb, null, this);
            _this.shark = shark;
        } else if (typeof _this.shark === "undefined") {
            return;
        }

        if (typeof shark !== "undefined" && shark.active) {
            const sharkVelocity = _this.sharkVelocity;
            const crabPosition = crab.body.position;
            const sharkPosition = shark.body.position;

            if (Math.abs(crabPosition.x - sharkPosition.x) > 100) {
                if (crabPosition.x < sharkPosition.x) {
                    shark.setVelocityX(-1 * sharkVelocity);
                    shark.anims.play('sharkLeft', true);
                } else if (crabPosition.x > sharkPosition.x) {
                    shark.setVelocityX(sharkVelocity);
                    shark.anims.play('sharkRight', true);
                }
            }

            if (crabPosition.y < sharkPosition.y) {
                shark.setVelocityY(-1 * sharkVelocity);
            } else if (crabPosition.y > sharkPosition.y) {
                shark.setVelocityY(sharkVelocity);
            }
        }
    }.bind(this);

    _this.parseControls = function parseControls(){
        const cursors = _this.cursors;
        const crab = _this.crab;
        const crabHorizontalVelocity = _this.crabHorizontalVelocity;
        const crabJumpVelocity = _this.crabJumpVelocity;

        if (cursors.left.isDown) {
            crab.setVelocityX(-1 * crabHorizontalVelocity);

            crab.anims.play('walk', true);
        } else if (cursors.right.isDown) {
            crab.setVelocityX(crabHorizontalVelocity);

            crab.anims.play('walk', true);
        }  else {
            crab.setVelocityX(0);

            crab.anims.play('stand');
        }

        if ((cursors.space.isDown || cursors.up.isDown) && crab.body.blocked.down === true) {
            crab.setVelocityY(crabJumpVelocity);
        } else if (cursors.down.isDown && crab.body.blocked.down === false) {
            let currentYVelocity = crab.body.velocity.y;
            crab.setVelocityY(currentYVelocity - crabJumpVelocity / 75);
        }
    }.bind(this);

    _this.initSceneValues = function initSceneValues(){
        let mainCamera = _this.sceneScope.cameras.main;
        let cameraHeight = mainCamera.height;
        let cameraWidth = mainCamera.width;
        let crabGravity = cameraHeight * 0.25;
        
        _this.crabJumpVelocity = _this.calculateJumpVelocity(crabGravity, cameraHeight * 0.75);

        _this.cameraHeight = cameraHeight;
        _this.cameraWidth = cameraWidth;
        _this.crabHorizontalVelocity = cameraWidth * 0.25;
        _this.swimmerVelocity = cameraWidth * 0.25;
        _this.sharkVelocity = _this.swimmerVelocity * 0.15;
        _this.crabGravity = crabGravity;
        _this.swimmerCeiling = cameraHeight * 0.25;
        _this.swimmerFloor = cameraHeight * 0.75;
        _this.crabBounce = 0.25;
        _this.crabStartX = cameraWidth * 0.5;
        _this.crabStartY= cameraHeight * 0.5;
    }.bind(this);

    _this.updateDifficulty = function updateDifficulty(){
        let timeScale = 1 + _this.score / 500;
        if(timeScale > 3.5) {
            timeScale = 3.5;
        }
        _this.spawnSwimmersTimer.timeScale = timeScale;
    }.bind(this);

    _this.initCrab = function initCrab() {
        const sceneScope = _this.sceneScope;
        const crabXPosition = _this.crabStartX;
        const crabYPosition = _this.crabStartY;
        const crabBounce = _this.crabBounce;
        const crabGravity = _this.crabGravity;
        const crab = createCrab(sceneScope, crabXPosition, crabYPosition, crabBounce, crabGravity);
        _this.crab = crab;
    }.bind(this);

    _this.swimmerUpdateCb = function swimmerUpdateCb(swimmer){ 
        const swimmerPositionX = swimmer.body.position.x;

        if(swimmerPositionX > _this.cameraWidth + 50 || swimmerPositionX < -50){

            // @future Is this used?
            if(swimmer.data.get('escapes') === true) {
                _this.updateLives(-1);
            }

            swimmer.destroy();
        }
    };

    _this.updateShrimp = function updateShrimp(){ 
        const shrimp = _this.shrimp;
        const shrimpExists = (typeof shrimp !== "undefined" && shrimp.active === true);

        if(shrimpExists === false){
            return;
        }

        const shrimpPositionX = shrimp.body.position.x;

        if(shrimpPositionX > _this.cameraWidth + 50 || shrimpPositionX < -50){

            if(shrimp.data.get('escapes') === true) {
                _this.sounds.escape.play();
                _this.updateLives(-1);
            }

            shrimp.setActive(false).setVisible(false).disableBody();
        }
    }.bind(this);

    _this.collectSwimmerCb = function collectSwimmerCb (crab, swimmer) {
        
        _this.updateScore(swimmer.data.get('touchValue'));

        const hostile = swimmer.data.get('hostile');
        const touchSound = swimmer.data.get('touchSound');

        if(hostile === true && crab.data.get('invulnerable') === false){
            _this.sounds[touchSound].play();

            crab.data.set('invulnerable', true);
            crab.setTint(0x757575);
            _this.updateLives(-1);

            _this.sceneScope.time.delayedCall(1000, function(){
                crab.clearTint();
                crab.data.set('invulnerable', false);
            });
        } else if(hostile !== true) {
            _this.sounds[touchSound].play();
        }

        if(swimmer.data.get('type') === "shark") {
            swimmer.setActive(false).setVisible(false).disableBody();

            _this.sceneScope.time.delayedCall(7500, function(){
                swimmer.setActive(true).setVisible(true).enableBody().setPosition(0, _this.cameraHeight * 0.5);
            });
        } else if(swimmer.data.get('type') === "shrimp") {
            swimmer.setActive(false).setVisible(false).disableBody();
        } else {
            swimmer.destroy();
        }
    };

    _this.updateScore = function updateScore(scoreChange){
        let score = _this.score;
        score = score + scoreChange;

        _this.setScore(score);
        _this.updateDifficulty();
        _this.gainLivesFromScore();
    }.bind(this);

    _this.updateLives = function updateLives(livesChange){
       let lives = _this.lives + livesChange;
        _this.setLives(lives);
    }.bind(this);

    _this.setLives = function setLives(lives){
        _this.livesDisplay.clear(true, true);

        if (lives <= 0) {
            _this.sounds.theme.stop();
            _this.sceneScope.scene.restart();
        } else {
            _this.lives = lives;

            if(lives > 0){
                _this.livesDisplay.createMultiple({
                    key: 'life',
                    repeat: lives -1,
                    setXY: { x: 54, y: 54, stepX: 64 }
                });
            }
        }
    }.bind(this);

    _this.setScore = function setScore(score){
        
        if (score < 0 ){
            score = 0;
        }

        _this.score = score;

        let paddedScore = score;

        if (score < 10000) {
            paddedScore = ("0000" + score).slice(-5);
        }

        _this.scoreText.setText(paddedScore);
    }.bind(this);

    _this.spawnShrimp = function spawnShrimp() {
        const crab = _this.crab;
        const shrimpData = _this.shrimpData;
        const swimmerCeiling = _this.swimmerCeiling;
        const swimmerFloor = _this.swimmerFloor;
        const screenSide = Phaser.Math.Between(0, 1);
        const yPosition = Phaser.Math.Between(swimmerCeiling, swimmerFloor);
        const cameraWidth = _this.cameraWidth;
        let shrimp = _this.shrimp;
        let modifiedVelocity = _this.swimmerVelocity * shrimpData.velocityModifier;
        let shrimpAnimation;
        let xPosition;

        if(screenSide === 0) {
            // Left to Right
            xPosition = 0;
            shrimpAnimation = shrimpData.animationRight;
        } else {
            // Right to Left
            xPosition = cameraWidth;
            modifiedVelocity = -1 * modifiedVelocity;
            shrimpAnimation = shrimpData.animationLeft;
        }

        if(typeof shrimp === "undefined") {
            const sceneScopePhysics = _this.sceneScope.physics;
            shrimp = sceneScopePhysics.add.sprite(xPosition, yPosition, 'shrimp');
            _this.shrimp = shrimp;
 
            shrimp.setDataEnabled();
            shrimp.data.set('escapes', shrimpData.escapes);
            shrimp.data.set('hostile', shrimpData.hostile);
            shrimp.data.set('touchValue', shrimpData.touchValue);
            shrimp.data.set('type', shrimpData.type);
            shrimp.data.set('touchSound', shrimpData.touchSound);

            const shrimpWidth = shrimp.frame.width;
            const shrimpHeight = shrimp.frame.height;
            shrimp.setSize(shrimpWidth, shrimpHeight, false);

            const collectSwimmerCb = _this.collectSwimmerCb;
            _this.sceneScope.physics.add.overlap(crab, shrimp, collectSwimmerCb, null, this);
        } else {
            shrimp.setActive(true).setVisible(true).enableBody().setPosition(xPosition, yPosition);
        }

        shrimp.setVelocityX(modifiedVelocity);
        shrimp.anims.play(shrimpAnimation, true);
    }.bind(this);

    _this.spawnSwimmer = function spawnSwimmer(swimmerData){
        let cameraWidth = _this.cameraWidth;
        let swimmerCeiling = _this.swimmerCeiling;
        let swimmerFloor = _this.swimmerFloor;
        let screenSide = Phaser.Math.Between(0, 1);
        let yPosition = Phaser.Math.Between(swimmerCeiling, swimmerFloor);
        let modifiedVelocity = _this.swimmerVelocity * swimmerData.velocityModifier;
        let xPosition;
        let swimmerAnimation;

        if(screenSide === 0) {
            // Left to Right
            xPosition = 0;
            swimmerAnimation = swimmerData.animationRight;
        } else {
            // Right to Left
            xPosition = cameraWidth;
            modifiedVelocity = -1 * modifiedVelocity;
            swimmerAnimation = swimmerData.animationLeft;
        }

        let swimmer = _this.swimmers.create(xPosition, yPosition, 'swimmer');
        swimmer.setVelocityX(modifiedVelocity);
        swimmer.anims.play(swimmerAnimation, true);
        swimmer.setSize(swimmer.frame.width, swimmer.frame.height, false);
        swimmer.setDataEnabled();
        swimmer.data.set('escapes', swimmerData.escapes);
        swimmer.data.set('hostile', swimmerData.hostile);
        swimmer.data.set('touchValue', swimmerData.touchValue);
        swimmer.data.set('type', swimmerData.type);
        swimmer.data.set('touchSound', swimmerData.touchSound);

        return swimmer;
    }.bind(this);

    _this.spawnSwimmersCb = function spawnSwimmersCb(){
        let shrimp = _this.shrimp;

        const typeofShrimp = typeof shrimp;
        const shrimpExists = (typeofShrimp !== "undefined" && shrimp.active === true);

        if(shrimpExists === false){
            let spawnShrimp = Phaser.Math.Between(0, 4);

            if (spawnShrimp === 0) {
                _this.spawnShrimp();
                return;
            }
        }
        
        const actor = Phaser.Math.RND.pick(_this.swimmerData);
        _this.spawnSwimmer(actor);
    };

    _this.calculateJumpVelocity = function(gravity, maxHeight){
        let jumpVelocity = -1 * Math.sqrt(2 * gravity * maxHeight);

        return jumpVelocity;
    }.bind(this);

    // Public Properties

    // Phaser Scene Configuration
    this.key = "stage";
    this.active = false;
    this.visible = true;
    this.pack = false;
    this.cameras = null;
    this.physics = {};
    this.loader = {};
    this.plugins = false;

    // Private Properties
    _this.extraLives = 0;
    _this.lives = 0;
    _this.score = 0;
    _this.swimmerData = swimmerData;
    _this.shrimpData = shrimpData;
    _this.crab = undefined;
    _this.shark = undefined;
    _this.shrimp = undefined;
    _this.cursors = undefined;
    _this.swimmers = undefined;
    _this.sceneScope = undefined;
    _this.crabJumpVelocity = undefined;
    _this.crabHorizontalVelocity = undefined;
    _this.swimmerVelocity = undefined;
    _this.spawnSwimmersTimer = undefined;
    _this.cameraHeight = undefined;
    _this.cameraWidth = undefined;
    _this.swimmerCeiling = undefined;
    _this.swimmerFloor = undefined;
    _this.crabBounce = undefined;
    _this.scoreText = undefined;
    _this.livesDisplay = undefined;
    _this.sounds = undefined;

    //Constructor
	(function(_this, options){

        // Reveal or hide private scope dependent on options.
        if (options.revealPrivateScope) {
            this._this = _this;
        }

    }).bind(this)(_this, options);
};