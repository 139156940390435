"use strict";

/* globals Phaser */

// @todo Consider WebGL mode.
// @todo Consider audio settings.

module.exports = {
    type: Phaser.AUTO,
    parent: 'phaser-parent',
    width: 1920,
    height: 1080,
    banner: false,
    physics: {
        default: 'arcade',
        arcade: {
            debug: false,
        }
    },
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        // @todo Ensure that this is set correctly and doesn't need to be moved.
        autoRound: true,
    },
	render: {
        // @todo Ensure that this is set correctly and doesn't need to be moved.
		pixelArt: true,
	},
};